var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.paginationItems.data,"items-per-page":_vm.paginationParams.per_page,"page":_vm.paginationParams.page,"server-items-length":_vm.paginationItems.total,"loading":_vm.loading,"sort-by":"id","footer-props":_vm.footerProps},on:{"update:page":_vm.changePage,"update:items-per-page":_vm.changeItemsPerPage,"update:sort-by":_vm.sortBy,"update:sort-desc":_vm.sortDesc},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{staticStyle:{"min-width":"200px"}},[_c('v-text-field',{attrs:{"label":_vm.trans('fields.common.email'),"clearable":""},model:{value:(_vm.filters.email),callback:function ($$v) {_vm.$set(_vm.filters, "email", $$v)},expression:"filters.email"}})],1)],1),_c('v-row',[_c('v-btn',{staticClass:"ma-2",attrs:{"color":"primary","outlined":""},on:{"click":_vm.applyFilters}},[_vm._v(_vm._s(_vm.trans('buttons.common.apply')))]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"orange","outlined":""},on:{"click":_vm.clearFilters}},[_vm._v(_vm._s(_vm.trans('buttons.common.clear')))])],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("mysqlDate")(item.created_at))+" ")]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('user-link',{attrs:{"user-id":item.id,"user-email":item.email}})]}},{key:"item.is_demo",fn:function(ref){
var item = ref.item;
return [(item.is_demo)?_c('v-icon',[_vm._v("mdi-check-circle")]):_vm._e()]}},{key:"item.plan_valid_till",fn:function(ref){
var item = ref.item;
return [(item.notpayed)?[_vm._v(" "+_vm._s(_vm.trans('pages.plan.unpayed'))+" ")]:[_vm._v(" "+_vm._s(item.plan_valid_till)+" ")]]}},{key:"item.master_autopayment",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.setAutopayment(item)}},model:{value:(item.master_autopayment),callback:function ($$v) {_vm.$set(item, "master_autopayment", $$v)},expression:"item.master_autopayment"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }